import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import role from './modules/role'
import getters from './getters'
import account from './modules/account'
import product from './modules/product'
import allgong from './modules/allgong'
import uploadimg from './modules/uploadimg'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    role,
    account,
    product,
    allgong,
    uploadimg
  },
  getters // 全局挂载的getters
})

export default store
