import { AddRole, GetAllRole, GetRoleDetail, PutRole, DelRole } from '@/api/role'

export default {
  namespaced: true,
  state: {
    roleItem: {}// 角色信息
  },
  mutations: {
    setroleItem (state, item) {
      state.roleItem = item
    }
  },
  actions: {
    // 新增角色
    async  addrole ({ commit }, item) {
      await AddRole(item)
    },
    // 获取所有的角色
    async getallrole ({ commit }, item) {
      const res = await GetAllRole(item)
      commit('setroleItem', res)
      return res
      // console.log(res)
    },
    // 获取角色详情
    async getroledetail ({ commit }, id) {
      const res = await GetRoleDetail(id)
      return res
    },
    // 修改角色信息
    async putrole ({ commit }, item) {
      await PutRole(item)
      // console.log(res)
    },
    // 删除角色信息
    async delRole ({ commit }, id) {
      await DelRole(id)
    }
  }
}

// export default {
//   namespaced: true,
//   state: {},
//   mutations: {},
//   actions: {}
// }
