<template>
  <div>
    <el-dialog
      :visible="dialogVisible1"
      :before-close="handleClose1">
      <div class="add_pro">
        <h4>修改账号</h4>
        <el-form ref="editAccount" :rules="rules" :model="form" label-width="80px" label-position="top">
          <el-form-item label="姓名:" prop="name">
            <el-input disabled placeholder="请输入姓名" clearable v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="角色:" class="role" prop="role_name">
            <el-select disabled @visible-change ="showDown" v-model="form.role_name" placeholder="请选择角色">
              <el-option :key="item.id" v-for="item in roleItem" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号:" prop="username">
            <el-input placeholder="请输入账号" clearable v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input type="password"  clearable v-model="form.password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible1: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      form: {
        name: '',
        username: '',
        password: '',
        role_name: ''
      },
      oldForm: {},
      roleItem: [], // 角色信息
      rules: {
        name: [
          { required: true, message: '姓名不能为空', trigger: ['blur', 'change'] }
        ],
        role_name: [
          { required: true, message: '角色不能为空', trigger: ['blur', 'change'] }
        ],
        username: [
          { required: true, message: '账号不能为空', trigger: ['blur', 'change'] },
          { pattern: /^1[3-9]\d{9}$/, message: '账号输入格式有误', trigger: ['blur', 'change'] }
        ],
        password: [
          { pattern: /^[a-zA-Z0-9]{8,10}$/, message: '密码长度为8-10个字符', trigger: ['blur', 'change'] }
        ]
      },
      editId: ''
    }
  },
  methods: {
    // 获取账号详情
    async  getAcccountDetail (id) {
      this.editId = id
      const res = await this.$store.dispatch('account/getaccountdetail', id)
      this.form = res
      this.oldForm = { ...res }
    },
    // 点击确认时
    confirm () {
      // 1. 预校验
      this.$refs.editAccount.validate(async (valid) => {
        if (valid) {
          // 判断是否改变数据
          if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) return this.$message('您还没有进行修改操作', 'warning')
          // 判断选取的角色 有没有 改变
          if (this.form.role_name !== this.oldForm.role_name) {
            this.form.role = this.form.role_name
          }
          this.form.id = this.editId
          // 判断密码框是否为空,为空默认 12345678
          if (this.form.password === '') {
            this.form.password = '12345678'
          }
          // 发请求
          await this.$store.dispatch('account/putaccount', this.form)
          this.$message('修改成功')
          this.handleClose1()
          this.$emit('updateData1')
        }
      })
    },
    handleClose1 () {
      this.$emit('update:dialogVisible1', false)
    },
    // 下拉框出现时，隐藏时触发
    async  showDown () {
      if (!this.roleItem.length) {
        // 获取所有的角色数据
        const res = await this.$store.dispatch('role/getallrole', { page: 1, size: 99999 })
        this.roleItem = res.results
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog{
  padding-bottom: 20px;
  width: 700px;
  // height: 470px;
  background: #FFFFFF;
  border-radius: 5px 5px 5px 5px;
  .el-dialog__close{
    font-size: 20px !important;
  }
  .add_pro{
    h4{
      text-align:center;
      font-size: 18px;
      color: #666666;
      margin-bottom: 30px;
    }
     .el-form-item__label{
      font-weight: bold;
      font-size: 16px;
      color: #666666;
    }
    .role{
      input{
      width: 660px !important;
    }
    }

  }
  .el-input__inner {
    color: #333;
  }
}
</style>
