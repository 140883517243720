<template>
  <div>
    <el-upload
      action="#"
      list-type="picture-card"
      :file-list="fileList"
      :on-preview="handlePreview"
      :limit="count"
      :class="{ disabled: disabled }"
    >
      <i class="el-icon-plus" />
    </el-upload>

    <el-dialog title="图片预览" :modal="false" :visible="showImg" @close="showImg = false">
      <img :src="imgSrc" alt="" style="width: 100%">
    </el-dialog>
  </div>
</template>

<script>

// 专门用来 回显 图片的组件 !!!! (借助于 预览模式)

export default {
  props: {
    count: {
      type: Number,
      default: 1
    },
    fileList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // fileList: [],
      showImg: false,
      imgSrc: ''

    }
  },
  computed: {
    disabled () {
      return this.fileList.length >= this.count
    },
    isAllUpload () {
      return this.fileList.every(item => item.status === 'success')
    }
  },
  methods: {
    handlePreview (file) {
      // 图片
      this.showImg = true
      this.imgSrc = file.url
    }

  }

}

</script>

<style lang='less' scoped>
.disabled {
   /deep/ .el-upload--picture-card {
      display: none;
  }
}
/deep/ .el-upload--picture-card {
      width: 100px !important;
      height: 100px !important;
      position: relative;
    .el-icon-plus{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
/deep/ .el-upload-list__item-thumbnail{
      width: 100px !important;
      height: 100px !important;
  }
/deep/ .el-upload-list__item{
      width: 100px !important;
      height: 100px !important;
}
/deep/ .el-upload-list__item-delete{
  display: none !important;
}
/deep/ .el-upload-list__item-status-label{
  display: none !important;
}

/deep/ .el-upload-list__item.is-success.focusing .el-icon-close-tip {
  display: none !important;
}
</style>
