<template>
  <div>
    <el-upload
      action="#"
      list-type="picture-card"
      :file-list="fileList"
      :on-preview="handlePreview"
      :limit="count"
      :class="{ disabled: disabled }"
    >
      <i class="el-icon-plus" />
    </el-upload>

    <el-dialog title="图片预览" :modal="false" :visible="showImg" @close="showImg = false">
      <img :src="imgSrc" alt="" style="width: 100%">
    </el-dialog>
    <el-dialog title="视频预览" :modal="false" :visible="showImg1" @close="closeVideo">
      <video type="" autoplay controls :src="VideoSrc" style="width: 100%"></video>
    </el-dialog>
  </div>
</template>

<script>

// 专门用来 回显 图片和视频一起的组件 !!!! (借助于 预览模式)

export default {
  props: {
    count: {
      type: Number,
      default: 1
    },
    fileList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // fileList: [],
      showImg: false,
      imgSrc: '',
      VideoSrc: '',
      showImg1: false,
      vidSrc: require('@/assets/image/lookVideo.png')
    }
  },
  mounted () {
    this.fetchVideoUrl()
  },
  computed: {
    disabled () {
      return this.fileList.length >= this.count
    },
    isAllUpload () {
      return this.fileList.every(item => item.status === 'success')
    }
  },
  methods: {
    handlePreview (file) {
      file.url = file.videoS // 点击预览的时候 把图片换回来
      // 判断是图片 还是 视频 进行不同的 预览
      if (this.dealFileType(file.url).type === 'img') {
        // 图片
        this.showImg = true
        this.imgSrc = file.url
      } else {
        // 视频
        this.showImg1 = true
        this.VideoSrc = file.url
      }
    },
    // 根据后端返回的 url地址判断 是图片 还是 视频
    dealFileType (url) {
      // 支持的视频格式
      const reg = /^.*\.(mp|MP)4$/
      const reg1 = /^.*\.(mov|MOV)$/
      const reg2 = /^.*\.(avi|AVI)$/
      const reg3 = /^.*\.(wmv|WMV)$/
      const isMp4 = reg.test(url)
      const isMov = reg1.test(url)
      const isAvi = reg2.test(url)
      const isWmv = reg3.test(url)
      const VideoG = isMp4 || isMov || isAvi || isWmv
      let res = {}
      if (!url) return res
      res = {
        type: VideoG ? 'video' : 'img',
        url: VideoG ? url : this.$imgUrl + (url[0] === '/' ? url.slice(1) : url)
      }
      return res
    },
    // 把视频图片 用默认图片先展示
    fetchVideoUrl () {
      // 假设这是从后端获取视频地址的函数
      // console.log(this.fileList)
      this.fileList.forEach(item => {
        if (this.dealFileType(item.url).type === 'video') {
          item.videoS = item.url // 存视频的url 先存下来 定义一个字段
          item.url = this.vidSrc // 用默认图片代替 视频 url
        } else {
          item.videoS = item.url // 存图片的url
        }
      })
      // console.log(this.fileList, 888)
    },
    // 点击关闭视频预览
    closeVideo () {
      this.showImg1 = false
      this.fetchVideoUrl()
    }
  }

}

</script>

<style lang='less' scoped>
.disabled {
   /deep/ .el-upload--picture-card {
      display: none;
  }
}
/deep/ .el-upload--picture-card {
      width: 100px !important;
      height: 100px !important;
      position: relative;
    .el-icon-plus{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
/deep/ .el-upload-list__item-thumbnail{
      width: 100px !important;
      height: 100px !important;
  }
/deep/ .el-upload-list__item{
      width: 100px !important;
      height: 100px !important;
}
/deep/ .el-upload-list__item-delete{
  display: none !important;
}
/deep/ .el-upload-list__item-status-label{
  display: none !important;
}

/deep/ .el-upload-list__item.is-success.focusing .el-icon-close-tip {
  display: none !important;
}
</style>
