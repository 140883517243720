import { AddAccount, GetAccount, GetAccountDetail, PutAccount, DelAccount } from '@/api/account'

export default {
  namespaced: true,
  state: {
    accountItem: []// 账号信息
  },
  mutations: {
    setaccountItem (state, item) {
      state.accountItem = item
    }
  },
  actions: {
    // 新增账号管理
    async  addAccount ({ commit }, item) {
      await AddAccount(item)
      // console.log(res)
    },
    // 获取账号信息 10条一获取
    async getAccount ({ commit }, item) {
      const res = await GetAccount(item)
      commit('setaccountItem', res)
      return res
    },
    // 获取账号的全部信息
    async getAllAccount ({ commit }, item) {
      const res = await GetAccount(item)
      return res
    },
    // 获取账号详情
    async getaccountdetail ({ commit }, id) {
      const res = await GetAccountDetail(id)
      return res
    },
    // 更新账号信息
    async putaccount ({ commit }, item) {
      const res = await PutAccount(item)
      return res
    },
    // 删除账号信息
    async delaccount ({ commit }, id) {
      await DelAccount(id)
    }
  }
}
