import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 配置一级路由
  {
    path: '/',
    redirect: '/userfan'
  },
  {
    path: '/userfan',
    component: () => import('@/views/userfan/user-fan.vue')
  },
  {
    path: '/index',
    component: () => import('@/views/dashboad/home-page.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/login/login-page.vue')
  },
  {
    path: '/deal',
    component: () => import('@/views/dealwith/deal-page.vue')
  },
  {
    path: '/product',
    component: () => import('@/views/product/pro-page.vue')
  },
  {
    path: '/account',
    component: () => import('@/views/account/account-page.vue')
  },
  {
    path: '/role',
    component: () => import('@/views/role/role-page.vue')
  }
]
const router = new VueRouter({
  routes,
  // 路由切换页面的滚动行为
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// 修复 重复渲染路由 报错
// 获取原型对象上的push函数,
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
