import Vue from 'vue'
import HeadNav from '@/components/head/head-nav.vue'
import ImageUpload from '@/components/ImageUpload/image-upload.vue'
import ImagePing from '@/components/imagePing/image-ping.vue'
import showMessage from '@/utils/message'
import {
  Form,
  FormItem,
  Input,
  Button,
  MessageBox,
  Select,
  Option,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Switch,
  Upload,
  Timeline,
  TimelineItem,
  Collapse,
  CollapseItem
} from 'element-ui'

Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Timeline)
Vue.use(TimelineItem)
// 全局注册组件
Vue.component('HeadNav', HeadNav)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePing', ImagePing)

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = showMessage
