import request from '@/utils/request'

// 获取所有工单的信息
export const GetAllGong = (data) => request.get('/workOrders/workOrders/', { params: data })

// 获取工单详情
export const GetGongDetail = (id) => request.get(`/workOrders/workOrders/${id}/`)

// 修改工单状态 和 指派给处理人
// export const PutGongStatus = (data) => request.put(`/workOrders/workOrders/${data.id}/`, data)

export const PutGongStatus = (data) => request({
  method: 'put',
  url: `/workOrders/workOrders/${data.id}/`,
  data: data
})

// 获取工单的日志
export const GetGongLogs = (data) => request.get('/workOrders/records/', { params: data })

// 获取工单评论
export const GetGongPing = (data) => request.get('/comments/comments/', { params: data })

// 发表工单评论
export const AddPing = (data) => request.post('/comments/comments/', data)
