import { reqLogin, GetUserInfo } from '@/api/user'
import { setToken, getToken, removeToken } from '@/utils/cookie.js'
export default {
  namespaced: true,
  state: {
    token: getToken() || '',
    userInfo: {} // 用户信息
  },
  mutations: {
    // 存储token的mutation
    setToken (state, token) {
      // 将token存储到vuex中
      state.token = token
      // 将token存储到cookie中持久化
      setToken(token)
    },
    // 设置个人信息的mutation
    setUserInfo (state, info) {
      state.userInfo = info
    },
    // 移除token
    removeToken (state) {
      state.token = '' // vuex中清除
      removeToken() // 本地清除
    },
    // 移除个人信息
    removeUserInfo (state) {
      state.userInfo = {}
    }
  },
  actions: {
    // 点击登录
    async reqlogin ({ commit }, item) {
      const res = await reqLogin(item)
      // 存token
      commit('setToken', res.token)
    },
    // 获取用户信息
    async getUserInfo ({ commit }) {
      const res = await GetUserInfo()
      // console.log(res, 888)
      commit('setUserInfo', res.user_info)
      // return res.data.data
    },
    // 退出登录
    logout ({ commit }) {
      // 移除token
      commit('removeToken')
      // 移除用户信息
      commit('removeUserInfo')
      // 移除分页保留记录
      localStorage.removeItem('AccPage')
      localStorage.removeItem('ProPage')
      localStorage.removeItem('RolePage')
    }
  }
}
