import request from '@/utils/request'

// 新增账号管理
export const AddAccount = (data) => request.post('/users/users/', data)

// 获取账号信息
export const GetAccount = (data) => request.get('/users/users/', { params: data })

// 获取账号详情
export const GetAccountDetail = (id) => request.get(`/users/users/${id}/`)

// 修改指定的账号信息
export const PutAccount = (data) => request.put(`/users/users/${data.id}/`, data)

// 删除指定的账号信息
export const DelAccount = (id) => request.delete(`/users/users/${id}/`)
