import request from '@/utils/request'

// 新增产品
export const AddProduct = (data) => request.post('/products/products/', data)

// 获取产品信息
export const GetProduct = (data) => request.get('/products/products/', { params: data })

// 获取产品详情
export const GetProDetail = (id) => request.get(`/products/products/${id}/`)

// 更新产品信息
export const PutPro = (data) => request.put(`/products/products/${data.id}/`, data)

// 删除产品信息
export const DelPro = (id) => request.delete(`/products/products/${id}/`)
