<template>
  <div class="head-nav">
    <div class="container content">
      <div class="title">
        <img src="../../assets/image/logo2.png" alt="">
        <h3>淘鱼科技—用户反馈工单系统</h3>
      </div>
      <div class="nav">
        <ul v-if="$store.getters.is_staff">
          <li @click="changeTit(index,item.path)" :class="{active:checked === index}" v-for="(item,index) in nav" :key="index" >{{item.title}}</li>
        </ul>
        <ul v-else>
           <li @click="changeTit(index,item.path)" :class="{active:checked === index}" v-for="(item,index) in navOne " :key="index" >{{item.title}}</li>
        </ul>
      </div>
      <div class="user">
        <!-- <img src="../../assets/image/avtar.png" alt=""> -->
        <p>{{$store.getters.name}}</p>
        <img class="down" src="../../assets/image/down.png" alt="">
        <div class="loingOut">
          <div class="upuseritem" @click="edituseritem">
            <img src="../../assets/image/user2.png" alt="">
            <span>个人信息</span>
          </div>
          <div class="con" @click="LoginOut">
            <img src="../../assets/image/out.png" alt="">
            <span>退出登录</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 编辑个人信息的弹窗 -->
     <EditUseritem ref="EditUser" :dialogVisible1.sync="dialogVisible1"></EditUseritem>
    <!-- 编辑个人信息的弹窗 -->

  </div>
</template>

<script>
import EditUseritem from './components/edit-useritem.vue'
export default {
  components: {
    EditUseritem
  },
  data () {
    return {
      checked: 0,
      // 管理员展示
      nav: [
        { path: '/index', title: '所有工单' },
        { path: '/deal', title: '待处理' },
        { path: '/product', title: '产品管理' },
        { path: '/account', title: '账号管理' },
        { path: '/role', title: '角色管理' }
      ],
      // 非管理员显示
      navOne: [
        { path: '/index', title: '所有工单' },
        { path: '/deal', title: '待处理' }
      ],
      dialogVisible1: false
    }
  },
  created () {
    if (this.$route.path === '/index') {
      this.checked = 0
    }
    if (this.$route.path === '/deal') {
      this.checked = 1
    }
    if (this.$route.path === '/product') {
      this.checked = 2
    }
    if (this.$route.path === '/account') {
      this.checked = 3
    }
    if (this.$route.path === '/role') {
      this.checked = 4
    }
  },
  methods: {
    changeTit (index, path) {
      this.checked = index
      this.$router.push(path)
    },
    LoginOut () {
      this.$confirm('您确定要退出登录嘛？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout')
        this.$message('退出登录成功 !')
        this.$router.push('/login')
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消该操作'
        // })
      })
    },
    // 修改个人信息
    edituseritem () {
      this.dialogVisible1 = true
      this.$refs.EditUser.getAcccountDetail(this.$store.getters.user_id)
    }
  }
}
</script>

<style lang="less" scoped>
.head-nav{
  width: 100%;
  height: 80px;
  background: #4DA4FF;
  .content{
    // background-color: red;
    height: 80px;
    display: flex;
    justify-content: space-between;
    .title{
      width: 384px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        width: 50px;
        height: 50px;
      }
      h3{
        font-weight: bold;
        font-size: 24px;
        color: #FFFFFF;
      }
    }
    .nav{
      width: 500px;
      // background-color: red;
      padding-top: 40px;
      margin-left: 100px;
      ul{
        display: flex;
        // justify-content: space-between;
        width: 500px;
        li{
          cursor: pointer;
          font-size: 18px;
          color: #FFFFFF;
          padding-bottom: 7px;
          margin-right: 35px;
          &:last-child{
            margin-right: 0;
          }
          &:hover{
            font-weight: bold;
            border-bottom: 3px solid #FFFFFF;
          }
        }
        .active{
            font-weight: bold;
            border-bottom: 3px solid #FFFFFF;
        }
      }
    }
    .user{
      display: flex;
      padding-top: 40px;
      align-items: center;
      position: relative;
      cursor: pointer;
      img{
        width: 24px;
        height: 24px;
      }
      p{
        font-size: 16px;
        color: #FFFFFF;
        margin: 0 10px;
      }
      .down{
        width: 10px;
        height: 10px;
      }
      .loingOut{
        display: none;
        position: absolute;
        left: -15px;
        bottom: -110px;
        width: 150px;
        height: 110px;
        background: #fff;
        box-shadow: 2px 3px 6px 3px  rgba(0,0,0,0.06);
        .con{
          display: flex;
          padding: 5px 22px;
          img{
            width: 22px;
            height: 22px;
            margin-right: 13px;
          }
          span{
            font-size: 16px;
            color: #333333;
          }
          &:hover{
           span{
            color: #4da4ff !important;
           }
          }
        }
        .upuseritem{
          &:hover{
          span{
            color: #4da4ff !important;
           }
          }
          display: flex;
          padding: 20px 20px;
          img{
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
          span{
            font-size: 16px;
            color: #333333;
          }
        }
      }
      &:hover{
        .loingOut{
          display: block;
        }
      }
    }
  }
}
</style>
