import { uploadImg } from '@/api/uploadimg'
export default {
  namespaced: true,
  state: {},
  mutations: {

  },
  actions: {
    // 上传图片
    async uploadImg ({ commit }, item) {
      const res = await uploadImg(item)
      return res
    }
  }
}
