import { GetProduct, AddProduct, GetProDetail, PutPro, DelPro } from '@/api/product'

export default {
  namespaced: true,
  state: {
    productItem: {}// 产品信息
  },
  mutations: {
    setproductItem (state, item) {
      state.productItem = item
    }
  },
  actions: {
    // 获取产品信息
    async getPro ({ commit }, item) {
      const res = await GetProduct(item)
      commit('setproductItem', res)
    },
    // 新增产品
    async addPro ({ commit }, item) {
      await AddProduct(item)
    },
    // 获取产品信息
    async getprodetail ({ commit }, id) {
      const res = await GetProDetail(id)
      return res
    },
    // 更新产品信息
    async putpro ({ commit }, item) {
      await PutPro(item)
    },
    // 删除产品信息
    async delpro ({ commit }, id) {
      await DelPro(id)
    }
  }
}
