import request from '@/utils/request'

// 用户登录
import qs from 'qs'
// 用户登录接口
export const reqLogin = (data) => request({
  method: 'post',
  url: '/users/login/',
  data: qs.stringify(data),
  headers: {
    Accept: 'application/json'
  }
})

// 获取用户信息
export const GetUserInfo = () => request.post('/users/getUserInfo/')
