import Vue from 'vue'
import { Message } from 'element-ui'
// 鼠标单击 效果
Vue.directive('copy', {
  bind (el, clickType) {
    if (clickType.modifiers.dblclick) {
      el.addEventListener('dblclick', () => handleClick(el.innerText))
    } else {
      el.addEventListener('click', () => handleClick(el.innerText))
    }
  }
})

async function handleClick (text) {
  try {
    await navigator.clipboard.writeText(text) // 该方法 只能在 https 网页 中生效
    Message.success('复制成功')
  } catch (err) {
    Message.error('复制失败，请重新复制')
  }
}
