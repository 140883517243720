import { Message } from 'element-ui'

export default function showMessage (
  message = '请求数据失败',
  type = 'success',
  duration = 1500
) {
  Message({
    duration,
    message,
    type
  })
}
