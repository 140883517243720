import { render, staticRenderFns } from "./image-upload.vue?vue&type=template&id=13e725b5&scoped=true&"
import script from "./image-upload.vue?vue&type=script&lang=js&"
export * from "./image-upload.vue?vue&type=script&lang=js&"
import style0 from "./image-upload.vue?vue&type=style&index=0&id=13e725b5&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e725b5",
  null
  
)

export default component.exports