import { GetAllGong, GetGongDetail, PutGongStatus, GetGongLogs, GetGongPing, AddPing } from '@/api/allgong'

export default {
  namespaced: true,
  state: {
    gongItem: {}// 所有工单信息
  },
  mutations: {
    setgongItem (state, item) {
      state.gongItem = item
    }
  },
  actions: {
    // 获取所有的工单系统信息
    async getallgong ({ commit }, item) {
      const res = await GetAllGong(item)
      commit('setgongItem', res)
    },
    // 获取工单详情
    async getgongdetail ({ commit }, id) {
      const res = await GetGongDetail(id)
      return res
    },
    // 修改工单详情
    async PutGongStatus ({ commit }, item) {
      // console.log(item, 999999)
      await PutGongStatus(item)
      // console.log(res)
    },
    // 获取工单日志
    async GetGongLogs ({ commit }, item) {
      const res = await GetGongLogs(item)
      return res
    },
    // 获取工单评论
    async getgongping ({ commit }, item) {
      const res = await GetGongPing(item)
      return res
    },
    // 发表工单评论
    async addping ({ commit }, item) {
      await AddPing(item)
      // console.log(res)
    }
  }
}
