import request from '@/utils/request'

// 新增角色
export const AddRole = (data) => request.post('/users/roles/', data)

// 获取所有的角色
export const GetAllRole = (data) => request.get('/users/roles/', { params: data })

// 获取角色详情
export const GetRoleDetail = (id) => request.get(`/users/roles/${id}/`)

// 修改角色信息
export const PutRole = (data) => request.put(`/users/roles/${data.id}/`, data)

// 删除角色信息
export const DelRole = (id) => request.delete(`/users/roles/${id}/`)
